@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-checkered {
    background-image: linear-gradient(45deg, #000 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #000 75%),
      linear-gradient(45deg, transparent 75%, #000 75%),
      linear-gradient(45deg, #000 25%, #222 25%);

    background-size: 25px 25px;
    background-position:
      0 0,
      0 0,
      -13px -37px,
      37px 62px;
  }

  .transform-preserve-3d {
    transform-style: preserve-3d;
  }

  .stroke-check-gradient-fill {
    & path {
      stroke: url(#check-gradient-fill);
    }
  }

  .scrollbar-hidden {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }

  .scrollbar-custom {
    &::-webkit-scrollbar {
      width: 5px;
      @apply bg-sidebar;
    }
    &::-webkit-scrollbar-thumb {
      @apply bg-shade-600;
      border-radius: 4px;
    }
  }

  .vector-effect-non-scaling-stroke {
    & path {
      vector-effect: non-scaling-stroke;
    }
  }

  .before-after-range-slider {
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin-left: 2px;
      appearance: none;
      pointer-events: auto;
      width: 2rem;
      height: 600px;
      background: transparent;
      opacity: 0;
      cursor: ew-resize;
    }
    &::-moz-range-thumb {
      width: 2rem;
      margin-left: 2px;
      height: 600px;
      opacity: 0;
      pointer-events: auto;
      background: transparent;
      cursor: ew-resize;
    }
  }
}

:root {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

* {
  font-family: "Gillroy", sans-serif;
}

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
 */
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}
::before,
::after {
  --tw-content: "";
}

#__next {
  overflow-x: hidden;
}

img {
  width: 100%;
  height: 100%;
}
